import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
// Components
import Navbar from "../components/NavBar"
import Footer from "../components/Footer"

function PrivacyPolicy() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  return (
    <div className="relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title} | Privacy Policy</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Navbar sticky={true} />
      <div className="mx-auto w-full max-w-6xl py-8 font-poppins">
        <p className="mb-6 text-4xl font-bold">Privacy Policy</p>
        <div className="mb-6">
          <p className="mb-4 text-2xl font-bold">Welcome to Jellow Octopus</p>
          <p className="mb-4">
            Jellow Octopus is an Ethereum blockchain-based generative project
            consisting of 8,888 unique Octopus avatar’s displaying randomized
            traits. The Jellow Octopus team are existing business owners with a
            passion for quality art and community building. These terms of
            service (“TOS”) are intended to provide clear, transparent
            guidelines for responsible ownership and use of Jellow Octopus. Any
            questions can be sent along to the Creative Team, via Twitter or
            Discord.
          </p>
        </div>
        <div className="mb-6">
          <p className="mb-4 text-2xl font-bold">
            Ownership and Commercial Rights of Jellow Octopus NFT’s
          </p>
          <p className="mb-4">
            Jellow Octopus’ consists of 8,888 individual ERC-1155 tokens
            (individually “Jellow Octopus”, collectively “Jellow Octopus” or
            “NFTs”) which include unique generative artwork hosted on
            InterPlanetary File System (“IPFS”). By minting a Jellow Octopus NFT
            from our smart contract, purchasing it off the secondary market
            (e.g. OpenSea), or otherwise acquiring the Jellow Octopus through
            any other legitimate means or method, the Holder receives full and
            complete ownership, exclusive of commercial rights, to the NFT and
            the corresponding unique artwork. The Holder agrees not to use,
            utilize, portray, advertise, distribute or otherwise market any
            Jellow Octopus in any project or derivative work including ones that
            involves hate speech, racism, pornography, or any other illegal or
            unlawful content. Upon sale or transfer of the NFT, any ownership or
            commercial rights are immediately transferred to the new Holder. No
            refunds shall be issued to any Holder upon a full and complete
            lawful purchase of any NFT or Jellow Octopus. In the event that any
            Holder purchases a Jellow Octopus through the secondary market, such
            as OpenSea, Holder shall be held accountable and will be bound by
            the Terms of Service which govern said secondary market platform.
          </p>
          <p className="mb-4">
            Limitations of Liability for Gas, Failed Transactions, Smart
            Contract Bugs Participants in minting Jellow Octopus NFTs agree to
            hold the project Creative Team and for any losses incurred as a
            consequence of minting an NFT. These potential losses include any
            gas fees for failed transactions, any excessive gas fees charged due
            to website or smart contract bugs, and any loss of any NFT due to
            website or smart contract bugs.
          </p>
        </div>
        <div className="mb-6">
          <p className="mb-4 text-2xl font-bold">
            No Guarantees or Future Promises
          </p>
          <p className="mb-4">
            While Jellow Octopus has released a roadmap outlining future goals
            and plans for community development, we cannot guarantee to
            accomplish every item outlined during the pre-launch planning phase
            as ideas and projects evolve organically. You agree that your
            purchase of a Jellow Octopus from our initial launch of 8,888 NFTs
            is all you are guaranteed to receive with your initial purchase,
            whether through primary or secondary channels. Any future benefits
            are ancillary to this purchase and not to be taken into
            consideration with your initial purchase. You agree that you are not
            relying on any future commitments by Jellow Octopus in using this
            site and participating in our NFT launch.
          </p>
          <p className="mb-4">
            No Guarantees of Value Jellow Octopus NFTs were created purely as
            collectibles, not as investment vehicles or substitutes for
            cryptocurrency. We make absolutely no promise or guarantee that
            these NFTs will subsequently retain monetary value in fiat, cash or
            cryptocurrency.
          </p>
        </div>
        <div className="mb-6">
          <p className="mb-4 text-2xl font-bold">Taxes</p>
          <p className="mb-4">
            Each Holder is solely and entirely responsible for any and all
            Federal or State tax liabilities which may arise, be imposed, or
            enforced as a result of minting or reselling Jellow Octopus
          </p>
          <p className="mb-4">
            Class Action Waiver, Jurisdiction and Choice of Law You agree to
            waive any class action status, and any legal dispute around the
            Jellow Octopus project which you may choose to bring can only be
            done on an individual basis.
          </p>
        </div>
        <div className="mb-6">
          <p className="mb-4 text-2xl font-bold">Children</p>
          <p className="mb-4">
            The Jellow Octopus project is not targeted towards children. You
            agree that you are over the age of 18, or above the legal age of
            your jurisdiction, whichever is greater. Any individual under the
            age of 18 participating in this project should only do so under
            parental supervision.
          </p>
        </div>
        <div className="mb-6">
          <p className="mb-4 text-2xl font-bold">Privacy Policy</p>
          <p className="mb-4">
            Jellow Octopus will not collect any cookies, IP addresses, or user
            data in connection with your use of the website and app, with the
            exception of cookies related to browsing behavior for the Google
            analytics SEO which helps us enhance your experience with our site
            and relay visitor traffic statistics to the Google search engine.
            All project participants understand that the Ethereum blockchain
            network is a public blockchain, and all transaction history
            initiated through the website will be made public. Jellow Octopus
            has no influence on secondary marketplaces, such as OpenSea which
            have independent terms of service agreed to by you, should you
            choose to utilize these sites. You also understand that Jellow
            Octopus may work with third-party apps, such as Discord or
            Collab.Land, which collect and store user data. Should Jellow
            Octopus be required to collect or store data for future activities
            such as merchandise launches or transfer of any benefits such as
            airdrops, you will be required to agree to the collection of this
            data. This provision may be subject to change, when deemed fit by
            the Jellow Octopus team.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
